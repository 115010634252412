import React from "react";
import Image from "gatsby-image";
import { Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import SEO from "../components/seo";

/* About Page
============================================================================= */

const AboutPage = ({ data }) => {
	// Config
	const { sanityIsolaid } = data;
	const {
		_rawAbout: about,
		_rawAboutTwo: aboutTwo,
		aboutOneImage: imageOne,
		aboutTwoImage: imageTwo,
	} = sanityIsolaid;

	// Render
	return (
		<>
			<SEO title="About" />
			<div className="w-full flex lg:flex-row flex-col">
				<div className="lg:w-1/2 w-full pl-10 xl:pr-72 pr-12 lg:pt-24 pt-10 pb-4 flex flex-col">
					<h2 className="text-darkblue text-5xl font-sans font-black pb-6 ibm-plex">
						ABOUT
					</h2>
					<p className="text-darkblue text-base font-sans pb-6 ibm-plex block-content">
						<BlockContent blocks={about} />
					</p>
				</div>
				<div className="lg:w-1/2 w-full">
					<div className="w-full h-220 bg-beige py-24">
						<Image
							fluid={imageOne.asset.fluid}
							className="h-full w-full"
							imgStyle={{ objectFit: "contain" }}
						/>
					</div>
				</div>
			</div>
			<div className="w-full flex lg:flex-row flex-col-reverse">
				<div className="lg:w-1/2 w-full">
					<div className="w-full h-220 bg-beige py-24">
						<Image
							fluid={imageTwo.asset.fluid}
							className="h-full w-full"
							imgStyle={{ objectFit: "contain" }}
						/>
					</div>
				</div>
				<div className="lg:w-1/2 w-full pl-10 lg:pr-72 pr-12 lg:pt-24 pt-10 pb-4 flex flex-col">
					<p className="text-darkblue text-base font-sans pb-6 ibm-plex">
						<BlockContent blocks={aboutTwo} />
					</p>
				</div>
			</div>
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query AboutPageQuery {
		sanityIsolaid {
			_rawAbout(resolveReferences: { maxDepth: 10 })
			_rawAboutTwo(resolveReferences: { maxDepth: 10 })
			aboutOneImage {
				asset {
					fluid {
						base64
						aspectRatio
						src
						srcSet
						srcWebp
						srcSetWebp
						sizes
					}
				}
			}
			aboutTwoImage {
				asset {
					fluid {
						base64
						aspectRatio
						src
						srcSet
						srcWebp
						srcSetWebp
						sizes
					}
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default AboutPage;
